export const colors = {
  gray: {
    50: '#F0F2F4',
    100: '#D6D8DA',
    200: '#BBBEC2',
    300: '#9EA5AC',
    400: '#818B95',
    500: '#68727D',
    600: '#515860',
    700: '#3B3F44',
    800: '#323350',
    900: '#0B0D0F',
  },
  black: '#151515',
  darkBlue:"#0C0D30",
  darkGreen: 'rgb(0, 8, 4)',
  custom: {
    1: '#888E8F',
    2: "#1c1f43", // "#1c1f43",// '#1E1E1E',
    3: "#323350", //'#282828',
    4: '#373737',
    5: '#FFFFFF',
  },
  ['dark-blue']: {
    50: '#eceefe',
    100: '#d8dcfd',
    200: '#b1bafb',
    300: '#8b97f8',
    400: '#6475f6',
    500: '#3d52f4',
    600: '#3142c3',
    700: '#253192',
    800: '#182162',
    900: '#0c1031',
  },
  app: {
    background: {
    body: {
      dark: 'rgb(0, 8, 4)',
      light: '#FFFFFF',
    }
  }}
};
