import Icon, { IconProps } from '@chakra-ui/icon';
import { forwardRef } from '@chakra-ui/system';

export const Logo = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon
      ref={ref}
      viewBox="0 0 438 576"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M340.824 535.768C379.219 518.987 437.117 439.772 437.117 390.054C437.117 334.21 407.602 288.94 371.194 288.94C336.932 288.94 308.153 330.958 304.881 382.453C288.283 381.461 268.324 383.097 244.345 388.171C206.535 396.171 168.296 402.991 129.803 406.442C57.0437 412.965 7.93594 433.479 6.33618 475.073C6.33618 526.705 87.4154 575.959 191.128 575.959C250.135 575.959 304.725 559.482 340.824 535.768Z"
        fill="url(#paint0_linear_1_72)"
      />
      <path
        d="M431.082 399.358C409.569 446.2 276.464 399.358 180.2 358.186C85.5077 314.698 19.6274 274.889 35.963 231.091C57.4758 184.248 216.976 175.2 311.669 218.688C406.361 262.177 449.16 344.136 431.082 399.358Z"
        fill="#1A2A86"
      />
      <path
        d="M374.138 69.3708C384.394 119.087 320.932 132.999 188.881 160.24C87.0951 181.238 11.4238 311.976 0.761834 175.541C-3.33268 123.147 47.4486 30.0377 149.235 9.03976C251.021 -11.9582 356.187 15.0277 374.138 69.3708Z"
        fill="url(#paint1_linear_1_72)"
      />
      <path
        d="M255.951 276.809C249.286 301.418 186.793 340.881 120.989 323.06C55.1857 305.239 2.92729 230.251 9.59206 205.642C16.0479 181.804 66.5027 159.144 141.51 196.194C146.444 198.631 151.682 200.8 156.957 202.368C311.009 248.148 262.503 252.613 255.951 276.809Z"
        fill="#1A2A86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.111 69.1391C375.404 75.4067 375.55 81.1086 374.56 86.3413C361.134 50.7476 312.018 27.5309 248.718 24.1348C154.337 19.0713 117.36 51.9478 112.384 95.654C112.384 128.443 144.403 163.36 194.809 176.264L278.252 207.049C289.844 210.552 300.928 214.662 311.24 219.398C386.125 253.789 428.402 312.354 433.588 362.536C435.224 371.361 436.1 380.68 436.1 390.314C436.1 449.085 381.215 513.798 344.208 533.277C308.222 558.327 251.683 576 190.377 576C86.6639 576 6.58354 527.417 6.58354 476.113C6.58354 470.345 7.55766 464.978 9.43422 459.995C9.36668 461.02 9.33263 462.05 9.33263 463.084C9.33263 511.079 82.6754 549.986 173.148 549.986C263.621 549.986 336.964 511.079 336.964 463.084C336.964 430.639 303.448 402.347 253.78 387.42C253.796 387.418 253.811 387.415 253.826 387.412C228.596 378.279 203.431 368.097 180.388 358.242C122.201 331.519 74.9448 306.192 51.1264 280.465C31.8405 261.447 17.9268 240.196 12.507 224.111C7.14857 214.388 3.44721 198.782 1.58671 175.445C-2.63373 122.508 47.833 28.5814 149.407 7.62722C250.981 -13.327 356.05 14.1987 374.111 69.1391Z"
        fill="url(#paint2_linear_1_72)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.111 69.1392C375.404 75.4068 375.55 81.1088 374.56 86.3414C361.135 50.7477 312.018 27.531 248.718 24.135C154.337 19.0715 117.36 51.948 112.384 95.6541C112.384 128.443 144.403 163.36 194.809 176.265L278.252 207.049C289.844 210.552 300.928 214.662 311.24 219.398C386.125 253.789 428.403 312.354 433.589 362.536C435.224 371.361 436.1 380.68 436.1 390.315C436.1 449.085 381.215 513.798 344.208 533.277C308.222 558.327 251.683 576 190.377 576C86.6639 576 6.5836 527.417 6.5836 476.113C6.5836 470.345 7.55772 464.978 9.43428 459.995C9.36674 461.02 9.33269 462.05 9.33269 463.084C9.33269 511.079 82.6754 549.986 173.148 549.986C263.621 549.986 336.964 511.079 336.964 463.084C336.964 430.639 303.448 402.348 253.78 387.42C253.796 387.418 253.811 387.415 253.826 387.412C228.596 378.279 203.431 368.097 180.388 358.242C122.201 331.519 74.9449 306.192 51.1265 280.465C31.8406 261.447 17.9268 240.196 12.5071 224.111C7.14864 214.388 3.44727 198.782 1.58677 175.445C-2.63367 122.508 47.833 28.5815 149.407 7.62734C250.981 -13.3269 356.051 14.1988 374.111 69.1392Z"
        fill="url(#paint3_linear_1_72)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_72"
          x1="352.703"
          y1="431.619"
          x2="4.2183"
          y2="491.079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A2A86" />
          <stop offset="1" stopColor="#2C44FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_72"
          x1="108.269"
          y1="104.295"
          x2="383.244"
          y2="74.7432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A2A86" />
          <stop offset="1" stopColor="#2B43FB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_72"
          x1="339.961"
          y1="0.605345"
          x2="137.188"
          y2="593.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#566AFF" />
          <stop offset="0.493345" stopColor="#3347D1" />
          <stop offset="1" stopColor="#566AFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_72"
          x1="339.961"
          y1="0.605467"
          x2="137.189"
          y2="593.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#566AFF" />
          <stop offset="0.493345" stopColor="#3347D1" />
          <stop offset="1" stopColor="#566AFF" />
        </linearGradient>
      </defs>
    </Icon>
  );
});
